@use "sass:math";
@use "../global" as *;
@use "../layout" as *;
//数字で見るパーツ
//想定：.col-6_sm-12

.c-infographic {
  display: block;
  background-color: $gray-l;
  position: relative;
  &__inner {
    padding: 60px;
    @include addSectionSidePadding;
  }

  &__label {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
  }

  //インフォグラフィック
  &__graphic {
    width: 100%;
    height: 206px;
    margin-bottom: 40px;
    @include mq-max(sm) {
      height: 150px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &>img {
      display: block;
      line-height: 1;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      font-family: 'object-fit: contain; object-position: center;';
    }
  }

  //数字
  &__numbers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -10px -20px;
    //Modifier
    &--flexStart {
      justify-content: flex-start;
    }

    &-wrap {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__number {
    text-align: center;
    padding: 0 10px 20px;
    //Modifier
    &--2Col {
      width: 50%;
    }
    &--3Col {
      width: math.div(100%,3);
    }

    //サブ見出し
    &-sub {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $keyColor;
      line-height: 1;
      text-align: center;
      margin-bottom: 3px;
      @include mq-max(sm) {
        font-size: 14px;
      }

      //Modifier
      &--inline {
        display: inline-block;
      }
      &--fzLarge {
        font-size: 24px;
        @include mq-max(iPadPro-v) {
          font-size: 18px;
        }
      }
    }
    //数値
    &-data {
      display: inline-block;
      font-size: 65px;
      font-weight: 600;
      font-style: italic;
      color: $keyColor;
      line-height: 1;
      white-space: nowrap;
      @include mq-max(md) {
        font-size: 5vw;
      }
      @include mq-max(sm) {
        font-size: 8vw;
      }
      @include mq-max(xs) {
        font-size: 10vw;
      }
      &--fzLarge {
        font-size: 100px;
        @include mq-max(sm) {
          font-size: 80px;
        }
        @include mq-max(xs) {
          font-size: 64px;
        }
      }
    }
    //単位
    &-unit {
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      color: $keyColor;
      line-height: 1;
      white-space: nowrap;
      @include mq-max(md) {
        font-size: 18px;
      }
      @include mq-max(md) {
        font-size: 20px;
      }

      &--fzLarge {
        font-size: 24px;
        @include mq-max(md) {
          font-size: 22px;
        }
        @include mq-max(iPadPro-v) {
          font-size: 18px;
        }
      }
    }
  }

  //更新日
  &__update {
    position: absolute;
    right: 60px;
    bottom: 20px;
    @include mq-max(content) {
      right: $contentPadding;
    }
    @include mq-max(sm) {
      right: 20px;
    }
    @include mq-max(xs) {
      right: math.div($contentPadding,2);
    }
    &>p {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
    }
  }

  //補足パート
  &__comment {
    max-width: 800px;
    margin: 0 auto;
  }


  //1カラム用
  &__data {
    width: 100%;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    &-inner {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin: 0 -15px -30px;
      @include mq-max(sm) {
        flex-direction: column;
        align-items: center;
      }
    }
    &-left,&-right {
      padding: 0 15px 30px;
      min-width: 50%; //個々の値は要調整
    }
    &-left {}
    &-right {}

    &-list {}
    &-item {
      text-align: right;
      margin-bottom: 20px;
      @include mq-max(sm) {
        text-align: center;
      }
      &:last-child {
        margin-bottom: 0;
      }
      //2つ目以降
      &:nth-child(n + 2) {
        .c-infographic__number-sub {
          font-size: 11px;
        }
        .c-infographic__number-data {
          font-size: 63px;
          @include mq-max(md) {
            font-size: calc(5vw - .5vw);
          }
          @include mq-max(sm) {
            font-size: calc(8vw - .5vw);
          }
          @include mq-max(xs) {
            font-size: calc(10vw - .5vw);
          }
        }
        .c-infographic__number-unit {
          font-size: 15px;
        }
      }
    }

    &-graphic {
      width: 100%;
      &>img {
        display: block;
        line-height: 1;
        width: 100%;
        height: 270px;
        object-fit: contain;
        object-position: center bottom;
        font-family: 'object-fit: contain; object-position: center bottom;';
        @include mq-max(sm) {
          height: 200px;
        }
        @include mq-max(xs) {
          height: 150px;
        }
      }
    }
  }

}