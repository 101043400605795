@use "sass:math";
@use "../global" as *;

/////////////////////////////////////////////////////////////////////////////////////////////////////////

////  基本ブロックレイアウト

/////////////////////////////////////////////////////////////////////////////////////////////////////////

.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: $sectionPadding 0;
  @include mq-max(content) {
    padding: 0 $contentPadding $sectionPadding;
  }
  @include mq-max(sm) {
    padding: 0 20px $sectionPadding;//お好みで変更
  }
  @include mq-max(xs) {
    padding: 0 math.div($contentPadding,2) $sectionPadding;//お好みで変更
  }

  //基本的に.l-section内で使用
  &__inner {
    padding: 0 0 $sectionInnerPadding;//お好みで変更
    @include mq-max(content) {
      padding-bottom: $sectionInnerPadding - 20px;
    }
    &:last-child {
      padding-bottom: 0;
    }

    &--pbLarge {
      padding-bottom: $sectionInnerPadding + 20px;//調整して
      &:last-child {
        padding-bottom: 0;
      }
    }
    &--pbExtraLarge {
      padding-bottom: 180px;//調整して
      @include mq-max(content) {
        padding-bottom: 120px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  //基本的に.l-section__inner内で使用
  &__content {
    padding: 0 0 30px;//調整して
    &:last-child {
      padding-bottom: 0;
    }

    &--pbLarge {
      padding-bottom: 60px;//調整して
    }
  }

  //////////////////////////////////////////////////////////////////////////
  //Modifier
  //////////////////////////////////////////////////////////////////////////
  //横幅調整用
  &--full {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    & > .l-section__inner {
      max-width: $contentWidth;
      width: 100%;
      margin: 0 auto;
      @include addSectionSidePadding;
    }
  }
  &--wide {
    max-width: $contentWidth-wide;
    @include mq-max(bp1310) {
      padding-left: $contentPadding;
      padding-right: $contentPadding;
    }
    @include mq-max(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include mq-max(xs) {
      padding-left: math.div($contentPadding,2);
      padding-right: math.div($contentPadding,2);
    }
  }

  //余白バリエーション
  &--lg {
    padding-top: $sectionPadding + 30px;
    padding-bottom: $sectionPadding + 30px;
    @include mq-max(content) {
      padding-top: 0;
    }
  }
  &--noTop {
    padding-top: 0;
  }
  &--pbLarge {
    padding-bottom: 90px;//調整して
    @include mq-max(content) {
      padding-bottom: 80px;
    }
  }
  &--pb2x {
    padding-bottom: $sectionPadding * 2;
    @include mq-max(content) {
      padding-bottom: 90px;//調整して
    }
  }
  &--ptHalf {
    padding-top: math.div($sectionPadding,2);
    @include mq-max(content) {
      padding-top: 0;
    }
  }
}