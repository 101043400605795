@use "../global" as *;
//テキスト系のユーティリティクラス

.u-txt {
  //半分ボーダー
  &-bdr {
    padding: 0 5px;
    &--keyColor {
      background: linear-gradient(transparent 60%, $keyColor 60%);
    }
    &--yellow {
      background: linear-gradient(transparent 60%, #FFEF69 60%);
    }
  }

  &--bold {
    font-weight: 600;
  }

  &--keyColor {
    color: $keyColor;
  }
  &--red {
    color: $red;
  }
  &--white {
    color: $white;
  }

  &--size20 {
    font-size: 20px;
  }
  &--size18 {
    font-size: 18px;
  }
  &--size15 {
    font-size: 15px;
  }
}