@use "../global" as *;

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ハンバーガーメニュー

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-toggleBtn {
  @include mq-max($changePoint) {
    display: block;
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    span {
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px $keyColor;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2) {
        top: 18px;
      }
      &:nth-child(3) {
        top: 27px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }

    &::after {
      content: 'メニュー';
      font-size: 10px;
      font-family: $sanserif;
      font-weight: 600;
      color: $keyColor;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }

    html.is-lang--eng & {
      &::after {
        content: 'MENU';
      }
    }
  }
}