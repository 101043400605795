@use "../global" as *;
@use "sass:math";

////////////////////////////////////////////////////////////
//大枠
////////////////////////////////////////////////////////////
.l-footer {
  background-color: #F7F7F7;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 72px 0 60px;
    display: flex;
    justify-content: space-between;
    @include mq-max(lg) {
      padding: 50px $contentPadding 40px;
    }
    @include mq-max(md) {
      flex-direction: column;
      align-items: center;
    }
    @include mq-max(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include mq-max(xs) {
      padding-left: math.div($contentPadding, 2);
      padding-right: math.div($contentPadding, 2);
    }
  }
  &__block {
    @include mq-max(md) {
      margin-bottom: 30px;
    }
    &:last-child {
      @include mq-max(sm) {
        margin-bottom: 0;
      }
    }

    //Mod
    &--info {
      margin-right: 30px;
      @include mq-max(md) {
        margin-right: 0;
      }
    }
  }
}


////////////////////////////////////////////////////////////
//会社情報
////////////////////////////////////////////////////////////
.l-footerInfo {
  max-width: 350px;
  width: 100%;
  @include mq-max(lg) {
    max-width: 260px;
  }
  @include mq-max(md) {
    max-width: 100%;
  }
  &__logo {
    max-width: 169px;
    width: 100%;
    margin-bottom: 30px;
    @include mq-max(lg) {
      max-width: 200px;
    }
    @include mq-max(md) {
      max-width: 240px;
      margin-left: auto;
      margin-right: auto;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &>a {
      display: block;
      width: 100%;
      line-height: 0;
      transition: all $transition;
      @media (any-hover: hover) {
        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

//住所
.l-footerAddress {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  &__list {
    @include mq-max(md) {
      text-align: center;
    }
  }
  &__item {
    font-size: 15px;
    line-height: 1.85;
    @include mq-max(content) {
      font-size: 13px;
    }
    @include mq-max(md) {
      font-size: 15px;
    }
  }
}


////////////////////////////////////////////////////////////
//フッターリンク
////////////////////////////////////////////////////////////
.l-footerLinks {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  margin: 0 -10px -20px;
  @include mq-max(md) {
    width: 100%;
  }
  @include mq-max(sm) {
    flex-wrap: wrap;
    width: auto;
  }
  &__block {
    max-width: 230px;
    width: auto;
    padding: 0 10px 20px;
    @include mq-max(sm) {
      max-width: 50%;
      width: 100%;
    }
  }
}
.l-footerParentLink {
  &__item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__anchor,&__label {
    display: block;
    color: $textColor;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    @include mq-max(bp1100) {
      font-size: 14px;
    }
  }
  &__anchor {
    transition: all $transition;
    @media (any-hover: hover) {
      &:hover {
        color: $lightBlue !important;
      }
    }
  }
  &__label {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.l-footerChildLink {
  &__item {
    position: relative;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__anchor {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $textColor;
    padding-left: 1.4em;
    transition: all $transition;
    position: relative;
    @include mq-max(bp1100) {
      font-size: 13px;
    }
    &::before {
      @include fa('f054');
      line-height: 1;
      font-size: 12px;
      position: absolute;
      top: .45em;
      left: 3px;
      transition: all $transition;
      @include mq-max(bp1100) {
        top: .4em;
      }
    }
    @media (any-hover: hover) {
      &:hover {
        color: $lightBlue !important;
        &::before {
          left: 7px;
          color: $lightBlue !important;
        }
      }
    }
  }
}


////////////////////////////////////////////////////////////
// フッター下部
////////////////////////////////////////////////////////////
.l-footerBtm {
  background-color: $keyColor;
  padding: 21px 0;
  @include addSectionSidePadding;
  @include mq-max(lg) {
    padding-left: $contentPadding;
    padding-right: $contentPadding;
  }
  @include mq-max(sm) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq-max(sm) {
      flex-direction: column;
    }
  }

  &__list {
    display: flex;
    @include mq-max(sm) {
      margin-bottom: 15px;
    }
  }
  &__item {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__anchor {
    color: $white;
    font-size: 11px;
    font-weight: 400;
  }

  //Mod
  &--en {
    .l-footerBtm {
      &__inner {
        justify-content: flex-end;
      }
    }
  }
}

//コピーライト
.l-copyright {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: $white;
  text-align: right;
  @include mq-max(sm) {
    text-align: left;
  }
  @include mq-max(xs) {
    font-size: 11px;
  }
  // @include mq-max(bp400) {
  //   max-width: 80%;
  // }
}

