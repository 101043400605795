@use "../global" as *;

.l-navigation {
  //  background-color: $white;
    height: 100%;
    @include mq-max($changePoint) {
      display: none;
      height: auto;
    }

    //ハンバーガーメニューオープン時に追加されるクラス
    &.is-navOpen {}

    &__container {
      display: flex;
      height: 100%;
      @include mq-max($changePoint) {
        flex-direction: column;
        height: calc(100vh - #{$headerHeight-sp});
        overflow-y: scroll;
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include mq-max($changePoint) {
        flex-direction: column-reverse;
      }
    }
    &__contact {
      width: 100px;
      margin-left: 6px;
      @include mq-max($changePoint) {
        width: 100%;
        margin: 0;
      }
      &>a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $keyColor;
        color: $white;
        padding: 0 10px;
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 500;
        transition: all $transition;
        @include mq-max($changePoint) {
          height: 60px;
          font-size: 15px;
          flex-direction: row;
        }
        &::before {
          content: '';
          background: url('/inc/image/common/ico/ico_mail.svg')no-repeat center / contain;
          width: 28px;
          height: 21px;
          display: block;
          line-height: 1;
          margin-bottom: 8px;
          @include mq-max($changePoint) {
            margin-bottom: 0;
            margin-right: 7px;
          }
        }
        &:hover {
          color: $white;
          background-color: $lightBlue;
        }
      }
    }

    //上gnavi
    &__lang {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
      padding-right: 12px;
      @include mq-max($changePoint) {
        margin: 0;
        background-color: $white;
        padding: 10px 15px;
        justify-content: center;
      }
      &::before {
        @include fa('f0ac');
        line-height: 1;
        margin-right: 6px;
        color: $keyColor;
      }
      &-item {
        margin-right: 12px;
        position: relative;
        &::after {
          content: '/';
          font-size: 13px;
          display: inline-block;
          color: $textColor;
          position: absolute;
          top: 50%;
          right: -8px;
          transform: translateY(-50%);
        }
        &:last-child {
          margin-right: 0;
          &::after {
            content: none;
          }
        }
        &>a {
          font-size: 14px;
          color: $textColor;
          &:hover {
            color: $lightBlue;
          }
          &.current {
            color: $lightBlue;
          }
        }
        &--jp {
          &>a {
            font-weight: 600;
          }
        }
        &--en {
          &>a {
            font-family: $serif-en;
            font-weight: 400;
          }
        }
      }
    }

    //下gnavi
    &__bottom {
      // margin-bottom: 10px;
      // @include mq-max($changePoint) {
      //   margin-bottom: 0;
      // }
    }
    &__inner {
      padding: 0;
      display: flex;
      // height: 100%;//menu.jsで指定した値が優先される
      @include mq-max($changePoint) {
        display: block;
        // height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
        // overflow-y: scroll;
      }
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &:last-child {
        margin-right: 0;
      }
      @include mq-max($changePoint) {
        display: block;
        height: auto;
        margin-right: 0;
        border-top: 1px solid $gray;
        &:last-child{
          border-bottom: 1px solid $gray;
        }
      }
      a {
        font-size: 15px;
        @include mq-max($changePoint) {
          background-color: $white;
          position: relative;
        }
        &::after {
          @include mq-max($changePoint) {
            @include fa('f105');
            font-size: 20px;
            color: $gray;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }
      }
      &>a {
        text-decoration: none;
        color: $textColor;
        display: inline-block;
        // display: flex;
        // align-items: center;
        // height: 100%;
        padding: 10px 10px;
        @include mq-max(content) {
          padding: 10px 8px;
        }
        @include mq-max($changePoint) {
          display: block;
          font-weight: 600;
          padding: 15px 45px 15px 30px;
        }
        &:hover {
          color: $lightBlue;
        }

        &.current {
          color: $lightBlue;
        }
      }

      //下層メニュー
      &.has-dropdown {
        &>a {
          position: relative;
          &::after {
            @include fa('f107');
            margin-left: 5px;
            color: $gray;
            @include mq-max($changePoint) {
              margin: 0;
              @include fa('f067');
              font-size: 18px;
              position: absolute;
              top: 50%;
              right: 16px;
              transform: translateY(-50%);
            }
          }
          &.has-dropdown {
            &::after {
              @include mq-max($changePoint) {
                content: '\f068';
              }
            }
          }
        }

        .l-dropDown {
          position: absolute;
          top: 50px - 30px; // headerの高さ - 30px
          left: 50%;
          transform: translateX(-50%);
          visibility: hidden;
          opacity: 0;
          transition: all .5s ease;
          z-index: -1;
          overflow: hidden;
          @include mq-max($changePoint) {
            position: relative;
            transition: none;
            top: auto;
            opacity: 1;
            visibility: visible;
            z-index: 1;
          }
          li {
            // border-bottom: 1px solid $gray;
            padding: 0 15px;
            background-color: $white;
            @include mq-max($changePoint) {
              // border-bottom: none;
              padding: 0;
            }
            &:last-child {
              // border: none;
              a {
                border-bottom: none;
              }
            }
            a {
              background-color: $white;
              padding: 10px 8px;
              white-space: nowrap;
              display: block;
              text-decoration: none;
              color: $textColor;
              font-size: 14px;
              width: 100%;
              @include mq-max($changePoint) {
                line-height: 0;
                height: 0;
                padding: 0;
                opacity: 0;
                transition: height $transition,
                            opacity 0.4s,
                            padding $transition,
                            line-height $transition;
              }
              &::after {
                @include mq-max($changePoint) {
                  font-size: 16px;
                }
              }
            }
          }
        }

        // クリック時
        &.isDropdownOpen {
          &>a {
            &::after {
              @include mq-max($changePoint) {
                content: '\f068';
              }
            }
          }
          .l-dropDown {
            top: 50px;
            opacity: 1;
            visibility: visible;
            box-shadow: $box-shadow;
            @include mq-max($changePoint) {
              top: auto;
              opacity: 1;
              display: block;
              box-shadow: none;
            }
            &>li {
              @include mq-max($changePoint) {
                border-bottom: 1px solid $white;
              }
              &:first-child {
                // border-top: 1px solid $gray;
              }
              &:last-child {
                // border-bottom: none;
                @include mq-max($changePoint) {
                  border-bottom: none;
                }
              }
            }
            a {
              transition: color $transition,
                          height $transition;
              border-bottom: 1px solid $gray;
              @include mq-max($changePoint) {
                border-bottom: none;
              }
              &:hover {
                color: $lightBlue;
              }
              @include mq-max($changePoint) {
                background-color: $gray-l;
                opacity: 1;
                line-height: 1.5;
                height: 50px;
                padding: 15px 45px 15px 50px;
                overflow: hidden;
              }
            }
          }
        }

      }
    }
  }
