@use "../global" as *;
//テーブル
//テキストアラインはユーティリティクラスを使ってください。

.c-tbl {
  //共通設定
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  th {
    border-top: 1px solid $keyColor;
    border-bottom: 1px solid $keyColor;
  }
  td {
    border-top: 1px solid $bdrColor;
    border-bottom: 1px solid $bdrColor;
  }
  th,td {
    // border: 1px solid $bdrColor;
    padding: 20px 30px;
    text-align: left;
    @include mq-max(xs) {
      padding: 15px 10px;
    }
  }


  //テーブルタイプ
  //ブロック
  &--block {
    tbody {
      tr {
        &:not(:last-child) {
          td{
            @include mq-max(xs) {
              border-bottom: none;
            }
          }
        }
        &:last-child {
          td {
            @include mq-max(xs) {
              border-bottom-color: $keyColor;
            }
          }
        }
        th {
          width: 160px;
          @include mq-max(sm) {
            width: 130px;
          }
          @include mq-max(xs) {
            width: 100%;
            border-bottom: none !important;
            text-align: left;
          }
        }
        // td {}
        th,td {
          @include mq-max(xs) {
            display: block;
            width: 100%;
          }
        }
      }
    }
    &--thLarge {
      tr {
        th {
          width: 230px !important;
          @include mq-max(xs) {
            width: 100% !important;
          }
        }
      }
    }
  }
}