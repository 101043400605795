@use "../global" as *;
// クラス付与型ホバーアクション
// クラスのありなしでホバーをカスタマイズできる
// hover_を頭に置くとわかりやすい

.u-hover {
  &--shadow {
    transition: all $transition !important;
    &:hover {
      box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
    }
  }

  &--underline {
    & > span {
      display: inline-block;
      position: relative;
      &::after {
        content: '';
        width: 0%;
        height: 2px;
        display: block;
        background-color: $lightBlue;
        position: absolute;
        left: 0;
        bottom: -3px;
        transition: all $transition !important;
        @include mq-max($changePoint) {
          content: none;
        }
      }
    }
    &:hover {
      & > span {
        &::after {
          width: 100%;
        }
      }
    }
  }
}