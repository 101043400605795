@use "sass:math";
@use "../../global" as *;

/////////////////////////////////////////////////////
//画像＋文章　ズレ
/////////////////////////////////////////////////////
$alternateGapHorizontal: 14%; //「%」、「px」
$alternateGapVertical: 60px; //「px」
$alternateGapImageWidth: 542px; //「%」、「px」
$alternateGapBgColor: $white;
$alternateGapChangePoint: sm;

.l-alternateGap {
  &__inner {
    display: flex;
    align-items: stretch;
    @include mq-max($alternateGapChangePoint) {
      flex-direction: column;
    }
  }
  &__img {
    z-index: 2;
    max-width: $alternateGapImageWidth;
    width: 100%;
    flex-shrink: 0;
    img {
      @extend %imgFormat;
    }
    @include mq-max(md) {
      max-width: 400px;
    }
    @include mq-max($alternateGapChangePoint) {
      max-width: 100%;
    }
  }
  &__body {
    position: relative;
    top: $alternateGapVertical;
    z-index: 1;
    width: calc((100% - $alternateGapImageWidth) + $alternateGapHorizontal);
    min-height: 100%;
    flex-shrink: 0;
    background-color: $alternateGapBgColor;
    margin-left: -1 * $alternateGapHorizontal;
    padding: 60px 40px 85px calc(40px + $alternateGapHorizontal);
    &::after {
      display: inline-block;
      content: '';
      width: 77%;
      height: 100%;
      position: absolute;
      background-image: url('/inc/image/raw/common/deco/business/pic_features-deco.png');
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      left: 23%;
      z-index: 2;
      opacity: .8;
    }
    @include mq-max(md) {
      padding: 60px 20px 60px calc(30px + $alternateGapHorizontal);
      flex-grow: 2;
      width: calc((100% - 400px) + $alternateGapHorizontal);
    }
    @include mq-max($alternateGapChangePoint) {
      top: 0;
      width: 100%;
      min-height: auto;
      padding: 20px 15px 30px;
      margin-left: 0;
      &::after {
        width: 97%;
        left: 3%;
      }
    }
    @include mq-max(sm) {
      padding: 15px 15px 25px 15px;
    }

    &--left {
      &::after {
        width: 98%;
        left: 2%;
      }
      @include mq-max(md) {
        &::after {
          width: 97%;
          left: -3%;
        }
      }
      @include mq-max(bp900) {
        &::after {
          width: 97%;
          left: 3%;
        }
      }
    }
  }
  &__ttl {
    position: relative;
    padding-left: 2em;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 16px;
    z-index: 3;
    @include mq-max(bp900) {
      font-size: 22px;
      margin-bottom: 10px;
    }
    &::before {
      position: absolute;
      top: -4px;
      left: 0;
      counter-increment: number;
      content: counter(number, decimal-leading-zero);
      font-family: $poppin;
      color: $keyColor;
      font-weight: 600;
      font-size: 39px;
      @include mq-max(bp900) {
        font-size: 29px;
      }
    }
  }
  &__desc {
    position: relative;
    z-index: 3;
    @include mq-max(bp900) {
      font-size: 15px;
    }
  }


  //Mod
  //反転
  &--reverse {
    .l-alternateGap {
      &__inner {
        flex-direction: row-reverse;
        @include mq-max($alternateGapChangePoint) {
          flex-direction: column;
        }
      }
      &__body {
        margin-right: -1 * $alternateGapHorizontal;
        padding: 60px calc(40px + $alternateGapHorizontal) 85px 40px;
        @include mq-max(md) {
          padding: 60px calc(30px + $alternateGapHorizontal) 60px 20px;
          flex-grow: 0;
        }
        @include mq-max($alternateGapChangePoint) {
          width: 100%;
          padding: 20px 15px 30px;
          margin-right: 0;
        }
        @include mq-max(sm) {
          padding: 15px 15px 25px 15px;
        }
      }
    }
  }


  //レイアウト
  &__wrap {
    counter-reset: number 0;
  }
  &__block {
    margin-bottom: $alternateGapVertical *2;
    @include mq-max($alternateGapChangePoint) {
      margin-bottom: 50px;
      max-width: 561px;
      margin-right: auto;
      margin-left: auto;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}