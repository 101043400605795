@use "../global" as *;
@use "sass:math";

.c-topicspath{
  max-width: $contentWidth;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  // text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;
  @include mq-max(content) {
    top: 10px;
    padding-left: $contentPadding;
    padding-right: $contentPadding;
  }
  @include mq-max(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include mq-max(xs) {
    padding-left: math.div($contentPadding,2);
    padding-right: math.div($contentPadding,2);
  }

  li{
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    font-family: $yuGothic;
    margin-right: 5px;
    &::after {
      @include fa('f105');
      display: inline-block;
      margin-left: 6px;
    }
    &:last-child {
      margin-right: 0;
      &::after {
        content: none;
      }
    }

    &>a {
      color: $keyColor;
      position: relative;
      &::after {
        content: '';
        display: inline-block;
        width: 0%;
        height: 2px;
        background-color: $keyColor;
        position: absolute;
        bottom: -1px;
        left: 0;
        transition: all $transition;
      }
      @media (any-hover: hover) {
        &:hover {
          color: $keyColor;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
