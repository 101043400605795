/*
モジュール系おまとめファイル
*/

//IE注意
@forward "ie-attention";

 // ページトップに戻る
@forward "pageTop";

 // ボックス
@forward "box";

 // ボタン
@forward "button";

//テーブル
@forward "table";

//リスト
@forward "list";

//見出し
@forward "heading";

//アコーディオン
@forward "accordion";

//タブ
@forward "tab";

//沿革
@forward "timeline";

// ラベル
@forward "label";

//お知らせ
@forward "newslist";

//電話番号
@forward "tel";

//コンバージョンエリア
@forward "cvArea";

//数字で見るコンポーネント
@forward "infographic";

//トピックパス
@forward "topicsPath";

//フロー
@forward "flow";

//吹き出し
@forward "hukidashi";

//画像
@forward "picture";

//ハンバーガーメニュー
@forward "toggle";

//リンク
@forward "link";

//カード
@forward "card";

//タイル
@forward "tile";