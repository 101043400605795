@use "../global" as *;

//アイコン付きリンク
.c-iconLink {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 100%;
  background-color: $white;
  color: $keyColor;
  line-height: 1.25;
  font-weight: 600;
  transition: color $transition,
              background-color $transition;
  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    margin-right: 10px;
  }
  &::after {
    @include fa('f054');
    margin-left: 20px;
    transition: $transition;
    line-height: 1;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $keyColor;
      background-color: $white;
      &::after {
        margin-left: 25px;
      }
    }
  }

  //Mod
  &--amusement {
    &::before {
      background: url('/inc/image/common/ico/ico_link-amusement.svg')no-repeat center center / contain;
    }
  }
  &--renewable-energy {
    &::before {
      background: url('/inc/image/common/ico/ico_link-renewable-energy.svg')no-repeat center center / contain;
    }
  }
  &--fund {
    &::before {
      background: url('/inc/image/common/ico/ico_link-fund.svg')no-repeat center center / contain;
    }
  }
  &--marina {
    &::before {
      background: url('/inc/image/common/ico/ico_link-marina.svg')no-repeat center center / contain;
    }
  }
  &--paint {
    &::before {
      background: url('/inc/image/common/ico/ico_link-paint.svg')no-repeat center center / contain;
    }
  }
  &--concert-hall {
    &::before {
      background: url('/inc/image/common/ico/ico_link-concert-hall.svg')no-repeat center center / contain;
    }
  }
  &--restaurant {
    &::before {
      background: url('/inc/image/common/ico/ico_link-restaurant.svg')no-repeat center center / contain;
    }
  }
  &--real {
    &::before {
      background: url('/inc/image/common/ico/ico_link-real.svg')no-repeat center center / contain;
    }
  }
}


//扉リンク
.c-doorLink {
  display: block;

  &__thumb {
    margin-bottom: 25px;
    position: relative;
  }
  &__desc {
    color: $textColor;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    & img {
      width: 44px;
      height: 44px;
    }
    & span {
      display: inline-block;
      margin-left: 10px;
    }

    // Mod
    &--position {
      & img {
        margin-bottom: 6px;
      }
    }
  }
  &__arrow {
    width: 55px;
    height: 55px;
    background-color: rgba(#000010, .7);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
    transition: all $transition;
    &::before,&::after {
      content: '';
      display: inline-block;
      width: 26px;
      height: 22px;
      background: url('/inc/image/common/ico/ico_arrow-white.svg')no-repeat center / contain;
      position: absolute;
    }
    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50% ,-50%);
    }
    &::after {
      top: 50%;
      left: -35%;
      transform: translate(-50% ,-50%);
    }
  }


  @media (any-hover: hover) {
    &:hover {
      .c-doorLink {
        &__arrow {
          background-color: $keyColor;
          &::before {
            animation: arrowAnimationBefore .2s ease 0s 1 normal forwards;
          }
          &::after {
            animation: arrowAnimationAfter .2s ease 0s 1 normal forwards;
          }
        }
      }
    }
  }
}

@keyframes arrowAnimationBefore {
  0% {
    left: 50%;
  }
  100% {
    left: 135%;
  }
}
@keyframes arrowAnimationAfter {
  0% {
    left: -35%;
  }
  100% {
    left: 50%;
  }
}

//矢印付き
.c-link {
  position: relative;
  @include mq-max(xs) {
    margin-right: 20px;
  }
  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include fa('f061');
    margin-left: 10px;
    transition: $transition;
    line-height: 1;
  }
  @media (any-hover: hover) {
    &:hover {
      &::after {
        margin-left: 15px;
      }
    }
  }
}


.c-txtLink {
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 5px;
  transition: opacity $transition !important;
  @media (any-hover: hover) {
    &:hover {
      opacity: .7;
    }
  }

  &[target="_blank"]{
    position: relative;
    &::after{
      position: relative;
      @include fa('f35d');
      margin-left: 0;
      -webkit-font-smoothing:antialiased;
    }
  }
  &[href$=".pdf"] {
    &::after {
      @include fa('f1c1');
      margin-left: 0;
      -webkit-font-smoothing:antialiased;
    }
  }
}