@use "../global" as *;

.c-box {
  width: 100%;
  background: $gray-l;
  border: 1px solid $gray-l;
  margin-bottom: 15px;
  container-type: inline-size;
  &:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: 40px 30px;
    @include mq-container(sm) {
      padding: 30px 20px;
    }
    @include mq-container(xs) {
      padding: 20px;
    }
    //Mod
    &--pLarge {
      padding: 60px;
      @include mq-container(sm) {
        padding: 40px;
      }
      @include mq-container(xs) {
        padding: 30px;
      }
    }
    &--pMedium {
      padding: 50px 45px;
      @include mq-container(sm) {
        padding: 40px 30px;
      }
      @include mq-container(xs) {
        padding: 20px;
      }
    }
  }
}

.c-boxDeco {
  position: relative;
  width: 100%;
  background: $gray-l;
  border: 1px solid $gray-l;
  margin-bottom: 15px;
  container-type: inline-size;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -30px;
    left: -10px;
    width: 190px;
    height: 282px;
    background: url('/inc/image/raw/common/deco/bg/pic_bgDecoration-bottom_v3.png')no-repeat center center / contain;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -100px;
    right: 0;
    width: 197px;
    height: 312px;
    background: url('/inc/image/raw/common/deco/bg/pic_bgDecoration-top_v3.png')no-repeat center center / contain;
  }

  &__inner {
    position: relative;
    z-index: 1;
    padding: 60px 80px 120px;
    @include mq-max(sm) {
      padding: 50px 60px 90px;
    }
    @include mq-max(xs) {
      padding: 30px 20px 60px;
    }

    // Mod
    &--pLarge {
      padding-top: 102px;
      padding-bottom: 78px;
      @include mq-max(xs) {
        padding-top: 60px;
        padding-bottom: 40px;
      }
    }
    &--pyEqual {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__logo {
    width: 91px;
    height: auto;
    margin: 0 auto 10px;
    text-align: center;
    @include mq-max(xs) {
      width: 78px;
    }
  }
}
