@use "../global" as *;
@use "sass:math";

//【使い方】.gridを.l-Gridで括る。
.l-Grid {
  overflow: hidden;
  &--18 {
    & > [class*="grid"] {
      @include gridTune(18px, none);
    }
  }
  &--42 {
    & > [class*="grid"] {
      @include gridTune(42px, none);
    }
  }
  &--42-mdDefault {
    & > [class*="grid"] {
      @include gridTune(42px, md);
    }
  }
  &--42-smDefault {
    & > [class*="grid"] {
      @include gridTune(42px, sm);
    }
  }
}


//max-width(できるだけl-sectionやl-section__innerと同じ場所では使わない。内部で使う)
.l-mw {
  margin: 0 auto;
  width: 100%;
  &--contentWidth {
    max-width: $contentWidth;
  }
  &--750 {
    max-width: 750px;
  }
  &--800 {
    max-width: 800px;
  }
  &--900 {
    max-width: 900px;
  }
}


//background共通設定
@mixin bg_scss() {
  margin: 0 0 $sectionPadding;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq-max(content) {
    padding-top: $sectionPadding;
  }
}

//背景色（.l-sectionを囲って使う）
//.l-bg--decoの設定値とmixin
$bgDecoRatio_lg: .6;
$bgDecoRatio_md: .45;
$bgDecoRatio_xs: .3;
@mixin bgDecoProperties($width, $height, $pos, $posValue) {
  width: $width;
  height: $height;
  @if $pos == 'top' {
    top: $posValue;
  }
  @if $pos == 'bottom' {
    bottom: $posValue;
  }
  @include mq-max(lg) {
    width: $width * $bgDecoRatio_lg;
    height: $height * $bgDecoRatio_lg;
    @if $pos == 'top' {
      top: $posValue * $bgDecoRatio_lg;
    }
    @if $pos == 'bottom' {
      bottom: $posValue * $bgDecoRatio_lg;
    }
  }
  @include mq-max(md) {
    width: $width * $bgDecoRatio_md;
    height: $height * $bgDecoRatio_md;
    @if $pos == 'top' {
      top: $posValue * $bgDecoRatio_md;
    }
    @if $pos == 'bottom' {
      bottom: $posValue * $bgDecoRatio_md;
    }
  }
  @include mq-max(xs) {
    width: $width * $bgDecoRatio_xs;
    height: $height * $bgDecoRatio_xs;
    @if $pos == 'top' {
      top: $posValue * $bgDecoRatio_xs;
    }
    @if $pos == 'bottom' {
      bottom: $posValue * $bgDecoRatio_xs;
    }
  }
}
.l-bg {
  @include bg_scss();
  &--keyColor {
    background-color: $keyColor;
  }
  &--lightGray {
    background-color: $gray-l;
  }
  &--gray {
    background-color: #EAEAEA;
  }
  &--deco {
    position: relative;
    &::before,&::after {
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    &::before {
      @include bgDecoProperties(414px, 617px, 'bottom', -84px);
      background-image: url('/inc/image/raw/common/deco/bg/pic_bgDecoration-bottom.png');
      background-position: bottom left;
      left: 0;
    }
    &::after {
      @include bgDecoProperties(456px, 721px, 'top', -60px);
      background-image: url('/inc/image/raw/common/deco/bg/pic_bgDecoration-top.png');
      background-position: top right;
      right: 0;
    }

    //パーツ調整
    .l-section {
      position: relative;
      z-index: 2;
    }
  }

  //decoの装飾別パターン(TOP用)
  &--deco_v2 {
    &::before {
      @include bgDecoProperties(341px, 617px, 'bottom', 0);
      background-image: url('/inc/image/raw/common/deco/bg/pic_bgDecoration-bottom_v2.png');
    }
    &::after {
      @include bgDecoProperties(374px, 481px, 'top', 0);
      background-image: url('/inc/image/raw/common/deco/bg/pic_bgDecoration-top_v2.png');
    }
  }

  // リクルートtop wako groupとはで使用
  &--recruitDeco {
    position: relative;
    &::before,&::after {
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      z-index: 0;
      pointer-events: none;
    }
    &::before {
      @include bgDecoProperties(252px, 465px, 'top', 0);
      background-image: url('/inc/image/raw/common/deco/recruit/bg/pic_bgDecoration-top.png');
      background-position: top left;
      left: 0;
    }
    &::after {
      @include bgDecoProperties(414px, 617px, 'bottom', 0);
      background-image: url('/inc/image/raw/common/deco/recruit/bg/pic_bgDecoration-bottom.png');
      background-position: bottom right;
      right: 0;
    }
    @include mq-max(bp900) {
      &::before,&::after {
        content: none;
      }
    }

    //パーツ調整
    .l-section {
      position: relative;
      z-index: 2;
      padding-top: 80px;
      padding-bottom: 140px;
      @include mq-max(content) {
        padding-top: 0;
        padding-bottom: 100px;
      }
      @include mq-max(sm) {
        padding-bottom: 90px;
      }
    }
  }


  // リクルートtop用 先輩インタビューで使用
  &--recruitDeco_v2 {
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 565px 176px 0 0;
      border-color: $accentColor transparent transparent transparent;
      opacity: .63;
      position: absolute;
      top: -9%;
      left: 0;
      z-index: 1;
      pointer-events: none;
    }

    @include mq-max(lg) {
      &::before {
        border-width: 378px 117px 0 0;
      }
    }
    @include mq-max(sm) {
      &::before {
        top: -4%;
      }
    }
    @include mq-max(xs) {
      &::before {
        border-width: 194px 60px 0 0;
      }
    }

    //パーツ調整
    .l-section {
      position: relative;
      z-index: 2;
      padding-top: 100px;
      padding-bottom: $sectionPadding *2;
      @include mq-max(content) {
        padding-top: 0;
        padding-bottom: 60px;
      }
      @include mq-max(sm) {
        padding-bottom: 50px;
      }
    }
  }


  &--mbNone {
    margin-bottom: 0;
  }
}


//.l-sectionを囲って拡張するクラス
$containerTriWidth: 595px;
$containerTriHeight: 197px;
.l-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: auto;
    min-height: 81px;
    max-height: 162px;
    background-color: $bgColor;
    aspect-ratio: 1440 / 162;
  }
  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $containerTriWidth $containerTriHeight 0 0;
    border-color: $accentColor transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    transform: translateX(-100%);
    opacity: 0;
    transition: opacity .8s, transform .8s;

    @include mq-max(bp1440) {
      border-width: ($containerTriWidth * .6) ($containerTriHeight * .6) 0 0;
    }
    @include mq-max(lg) {
      border-width: ($containerTriWidth * .4) ($containerTriHeight * .4) 0 0;
    }
    @include mq-max(sm) {
      border-width: ($containerTriWidth * .3) ($containerTriHeight * .3) 0 0;
    }
  }

  //パーツ調整
  .l-section {
    position: relative;
    z-index: 2;
    @include mq-max(content) {
      padding-top: math.div($sectionPadding, 2);
    }
  }

  // アニメーション
  &.is-fromLeft {
    &::after {
      transform: translateX(0);
      opacity: .63;
    }
  }
}