@use "../global" as *;

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  見出しクラス

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-ttl {
  //////////////////////////////////////////////////////////////////////////////////
  //メイン見出し（マクロ化済）
  //////////////////////////////////////////////////////////////////////////////////
  &-main {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 35px;
    color: $keyColor;
    @include mq-max(xs) {
      margin-bottom: 25px;
    }
    &__sub {
      display: block;
      font-size: 57px;
      font-weight: 700;
      font-family: $poppin;
      @include mq-max(xs) {
        font-size: 40px;
      }
    }

    //Mod
    &--mbLarge {
      margin-bottom: 50px;
      @include mq-max(xs) {
        margin-bottom: 40px;
      }
    }
  }



//  //////////////////////////////////////////////////////////////////////////////////
//  //中見出し
//  //////////////////////////////////////////////////////////////////////////////////
  &-middle {
    font-size: 35px;
    // font-weight: 600;
    font-weight: 700;
    font-family: $yuGothic;
    letter-spacing: .04em;
    color: $textColor;
    margin-bottom: 15px;
    @include mq-max(xs) {
      font-size: 26px;
    }

    //Mod
    &--mbLarge {
      margin-bottom: 25px;
    }
    &--small {
      font-size: 30px;
      @include mq-max(xs) {
        font-size: 24px;
      }
    }
  }



//  //////////////////////////////////////////////////////////////////////////////////
//  //小見出し
//  //////////////////////////////////////////////////////////////////////////////////
  &-small {
    font-size: 25px;
    // font-weight: 600;
    font-weight: 700;
    font-family: $yuGothic;
    letter-spacing: .04em;
    color: $textColor;
    margin-bottom: 10px;
    @include mq-max(xs) {
      font-size: 20px;
    }

    //Mod
    &--mbLarge {
      margin-bottom: 20px;
    }
    &--mbSmall {
      margin-bottom: 5px;
    }
    &--mbShort {
      margin-bottom: 0;
    }
    &--medium {
      font-size: 28px;
      @include mq-max(xs) {
        font-size: 22px;
      }
    }
  }

  &-xsmall {
    font-size: 22px;
    font-weight: 600;
    font-family: $yuGothic;
    letter-spacing: .04em;
    color: $textColor;
    margin-bottom: 10px;
    @include mq-max(xs) {
      font-size: 18px;
    }
    //Mod
    &--mbShort {
      margin-bottom: 0;
    }
  }



//  //////////////////////////////////////////////////////////////////////////////////
//  //全体Modifier
//  //////////////////////////////////////////////////////////////////////////////////
  //真ん中寄せ
  &--center {
    text-align: center;
  }
  &--sm-center {
    text-align: left;
    @include mq-max(sm) {
      text-align: center;
    }
  }

  //文字色
  &--white {
    color: $white;
  }
  &--keyColor {
    color: $keyColor;
  }

  //フォント
  &--poppins {
    font-family: $poppin;
  }
}


//カウント
//Note: c-ttl-main以外で使用することを想定
.c-countHeading {
  &::before {
    content: counter(index)'.';
    counter-increment: index;
    display: inline-block;
    margin-right: 5px;
  }
  &--container {
    counter-reset: index;
  }
}