@use "sass:math";
@use "../global" as *;


.c-picture {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  img {
    @extend %imgFormat;
  }


  //Mod
  &--round {
    overflow: hidden;
    border-radius: 5px;
  }
  &--shadow {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  }
}


.c-parallelPic {
  position: relative;
  padding: 0 16% 50px 13%;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 142px;
    opacity: .7;
    background-color: $accentColor;
    position: absolute;
    bottom: 1%;
    left: 11%;
    z-index: 2;
    transform: skew(-14deg);
  }

  @include mq-max(bp1440) {
    padding: 0 16% 50px 8%;
    &::before {
      left: 7%;
    }
  }
  @include mq-max(lg) {
    padding: 0 17% 50px 10%;
    &::before {
      left: 9%;
    }
  }
  @include mq-max(content) {
    padding: 0 17% 50px 7%;
    &::before {
      bottom: 1%;
      left: 7%;
    }
  }
  @include mq-max(bp1100) {
    padding: 0 18% 50px 7%;
  }
  @include mq-max(md) {
    &::before {
      bottom: 11%;
    }
  }
  @include mq-max(iPadPro-v) {
    padding: 0 10% 22px 8%;
    &::before {
      bottom: 0;
      left: 4%;
    }
  }
  @include mq-max(xs) {
    padding: 0 14% 22px 7%;
    &::before {
      height: 78px;
      left: 3%;
    }
  }

  &__wrapper {
    filter: drop-shadow(50px 45px 0px #F2EFF7);
    @include mq-max(md) {
      filter: drop-shadow(22px 20px 0px #F2EFF7);
    }
  }

  &__container {
    transform: skew(-14deg);
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  &__inner {
    transform: skew(14deg) scale(1.4);
    aspect-ratio: 713 / 508;
  }

  &__img {
    width: 100%;
    height: 100%;
    img {
      @extend %imgFormat;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;";
    }

    &--left {
      img {
        object-position: left center;
        font-family: "object-position: left center;";
      }
    }
  }
}