@use "../global" as *;
///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-size: 13px;
  padding: 11px 42px 11px 20px;
  width: auto;
  min-width: 170px;
  max-width: 100%;
  color: $white;
  background-color: $keyColor;
  line-height: 1.25;
  text-align: left;
  border: 1px solid $keyColor;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    content: '';
    display: block;
    width: 0%;
    height: 102%;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: -10%;
    z-index: 0;
    transform: translateY(-50%) skew(-20deg);
    transition: all $transition;
    pointer-events: none;
  }
  &::after {
    @include fa('f061');
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: right $transition;
    line-height: 1;
  }
  @media (any-hover: hover) {
    &:hover {
      color: $keyColor;

      &::before {
        width: 120%;
      }
    }
  }

  &__inner {
    display: inline-block;
    position: relative;
    z-index: 1;
  }


  //PDF
  &[href$=".pdf"] {
    @media (any-hover: hover) {
      &:hover {
        &::after {
          right: 16px;
          color: $keyColor;
        }
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &::after {
      color: $white;
      margin-left: 0;
    }
    @media (any-hover: hover) {
      &:hover {
        &::after {
          right: 16px;
          color: $keyColor;
        }
      }
    }
  }


  //Mod
  &--full {
    min-width: auto;
    width: 100%;
  }
  &--large {
    padding: 17px 34px 17px 26px;
    font-size: 16px;
    min-width: 300px;
    text-align: center;
    @include mq-max(sm) {
      width: 100%;
    }
  }
  &--bgWhite {
    background-color: $white;
    color: $keyColor;
    padding: 26px 34px 26px 26px;
    font-size: 16px;
    min-width: 300px;
    text-align: center;
    width: 100%;
    transition: all $transition;
    &::before {
      background-color: $keyColor;
    }
    @media (any-hover: hover) {
      &:hover {
        color: $white;

        &::before {
          width: 120%;
        }
      }
    }

    //PDF
    &[href$=".pdf"] {
      @media (any-hover: hover) {
        &:hover {
          &::after {
            right: 16px;
            color: $white;
          }
        }
      }
    }

    //外部リンク
    &[target="_blank"] {
      &::after {
        color: $keyColor;
        margin-left: 0;
      }
      @media (any-hover: hover) {
        &:hover {
          &::after {
            right: 16px;
            color: $white;
          }
        }
      }
    }
  }

  &--downArrow {
    &::after {
      @include fa('f063');
    }
  }
}


//アイコン付きボタン
.c-iconBtn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 19px;
  margin: 0 auto;
  padding: 20px 42px 21px 32px;
  width: 100%;
  max-width: 480px;
  background-color: $white;
  color: $keyColor;
  line-height: 1.25;
  border: 1px solid $keyColor;
  font-weight: 600;
  transition: color $transition,
              background-color $transition;
  @include mq-max(xs) {
    max-width: initial;
  }
  &::before {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 59px;
    height: 59px;
    margin-right: 20px;
  }
  &::after {
    @include fa('f054');
    position: absolute;
    right: 16px;
    transition: right $transition;
    line-height: 1;
  }
  @media (any-hover: hover) {
    &:hover {
      background-color: $accentColor-l;
      &::after {
        right: 11px;
      }
    }
  }

  // 事業領域の下層で使用
  &--small {
    padding: 10px 32px 10px 10px;
    font-size: 15px;
    &::before {
      margin-right: 7px;
      width: 55px;
      height: 55px;
    }
  }
  // 採用トップで使用
  &--large {
    padding: 15px 13px 14px 13px;
    &::before {
      width: 67px;
      height: 67px;
      margin-right: 14px;
    }
  }
  //Mod (事業領域下層で使用するアイコン)
  &--amusement {
    &::before {
      background: url('/inc/image/common/ico/ico_link-amusement.svg')no-repeat center center / contain;
    }
  }
  &--renewable-energy {
    &::before {
      background: url('/inc/image/common/ico/ico_link-renewable-energy.svg')no-repeat center center / contain;
    }
  }
  &--fund {
    &::before {
      background: url('/inc/image/common/ico/ico_link-fund.svg')no-repeat center center / contain;
    }
  }
  &--marina {
    &::before {
      background: url('/inc/image/common/ico/ico_link-marina.svg')no-repeat center center / contain;
    }
  }
  &--paint {
    &::before {
      background: url('/inc/image/common/ico/ico_link-paint.svg')no-repeat center center / contain;
    }
  }
  &--concert-hall {
    &::before {
      background: url('/inc/image/common/ico/ico_link-concert-hall.svg')no-repeat center center / contain;
    }
  }
  &--restaurant {
    &::before {
      background: url('/inc/image/common/ico/ico_link-restaurant.svg')no-repeat center center / contain;
    }
  }
  &--real {
    &::before {
      background: url('/inc/image/common/ico/ico_link-real.svg')no-repeat center center / contain;
    }
  }

  //別アイコン
  &--building {
    &::before {
      background: url('/inc/image/common/ico/ico_building.svg')no-repeat center center / contain;
    }
  }
  &--group {
    &::before {
      background: url('/inc/image/common/ico/ico_group.svg')no-repeat center center / contain;
    }
  }
  &--sprout {
    &::before {
      background: url('/inc/image/common/ico/ico_sprout.svg')no-repeat center center / contain;
    }
  }

  //採用で使用するアイコン
  &--newGraduate {
    &::before {
      background: url('/inc/image/common/ico/ico_newGraduate.svg')no-repeat center center / contain;
    }
  }
  &--midCareer {
    &::before {
      background: url('/inc/image/common/ico/ico_midCareer.svg')no-repeat center center / contain;
    }
  }
  &--partTime {
    &::before {
      background: url('/inc/image/common/ico/ico_partTime.svg')no-repeat center center / contain;
    }
  }
}


//画像をリンクにしたい場合
.c-bnrLink {
  display: block;
  background-color: $white;
  transition: all $transition;
  &[target=_blank] {
    &::after {
      content: none;
    }
  }

  img {
    @extend %imgFormat;
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: $accentColor-l;
    }
  }


  //Mod
  &--outline {
    border: 1px solid $keyColor;
  }

  // layout
  &__wrapper {
    max-width: 381px;
    margin: 0 auto;
  }
}