// タイル
@use "../global" as *;

.c-tileLink {
  position: relative;
  display: block;
  width: 100%;
  height: 256px;
  transition: all $transition;
  @include mq-max(sm) {
    height: auto;
  }
  &::after {
    @include mq-max(sm) {
      @include fa('f054');
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }

  &__wrap {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-max(sm) {
      padding: 15px 40px 15px 15px;
      justify-content: flex-start;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include mq-max(sm) {
      flex-direction: row;
    }
  }
  &__ico {
    width: 79px;
    height: 79px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 30px;
    @include mq-max(iPadPro-v) {
      margin-bottom: 20px;
    }
    @include mq-max(sm) {
      margin-bottom: 0;
      margin-right: 10px;
    }
    @include mq-max(xs) {
      width: 50px;
      height: 50px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
    font-family: $yuGothic;
    color: $textColor;
    line-height: 1.5;
    margin: 0;
    @include mq-max(iPadPro-v) {
      font-size: 20px;
    }
    @include mq-max(sm) {
      font-size: 24px;
    }
    @include mq-max(xs) {
      font-size: 22px;
    }
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: $accentColor-l;
    }
  }


  //レイアウト(カラム落ち無しを想定)
  &__list {
    display: flex;
    @include mq-max(sm) {
      flex-direction: column;
    }
  }
  &__item {
    width: calc(100% / 3);
    border-left: 1px solid $bdrColor;
    @include mq-max(sm) {
      width: 100%;
      border-left: none;
      border-top: 1px solid $bdrColor;
    }
    &:last-child {
      border-right: 1px solid $bdrColor;
      @include mq-max(sm) {
        border-right: none;
        border-bottom: 1px solid $bdrColor;
      }
    }
  }
}