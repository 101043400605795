@use "../global" as *;

//スライダーの設定を読み込み(不要なら記述削除)]
// @use "cvSlider" as *;

.c-cvArea {
  &__container {
    position: relative;
    overflow: hidden;
    padding: 0 20px;
    background-color: #EAEAEA;
    @include mq-max(sm) {
      padding: 0 15px;
    }
  }
  &__inner {
    padding: 76px 0 88px;
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    @include mq-max(sm) {
      padding: 50px 0 60px;
    }
    &::before, &::after {
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      pointer-events: none;
    }
    &::before {
      background: url('/inc/image/raw/common/deco/cvArea/pic_cvDecoration-left.png')no-repeat bottom left / contain;
      width: 414px;
      height: 477px;
      bottom: 0;
      left: -57px;
      @include mq-max(lg) {
        left: -7%;
      }
      @include mq-max(iPadPro-v) {
        width: 414px * .7;
        height: 477px * .7;
      }
      @include mq-max(xs) {
        width: 414px * .45;
        height: 477px * .45;
      }
    }
    &::after {
      background: url('/inc/image/raw/common/deco/cvArea/pic_cvDecoration-right.png')no-repeat top right / contain;
      width: 456px;
      height: 477px;
      top: 0;
      right: -57px;
      @include mq-max(lg) {
        right: -7%;
      }
      @include mq-max(iPadPro-v) {
        width: 456px * .7;
        height: 477px * .7;
      }
      @include mq-max(xs) {
        width: 456px * .45;
        height: 477px * .45;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }

  &__title {
    font-size: 32px;
    margin-bottom: 40px;
    text-align: center;
    color: $keyColor;
    @include mq-max(xs) {
      font-size: 23px;
      margin-bottom: 30px;
    }
    &--white {
      color: $white;
    }
  }

  &__desc {
    color: $keyColor;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 45px;
  }
}


.c-cvAreaBtns {
  &__list {
    display: flex;
    justify-content: center;
    @include mq-max(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__item {
    max-width: 397px;
    width: 100%;
    margin-right: 70px;
    @include mq-max(md) {
      margin-right: 40px;
    }
    @include mq-max(sm) {
      margin-right: 0;
      margin-bottom: 30px;
    }
    @include mq-max(xs) {
      max-width: 100%;
    }
    &:last-child {
      margin-right: 0;
      @include mq-max(sm) {
        margin-bottom: 0;
      }
    }
  }

  &__btn {
    font-size: 24px;
    padding: 11px 20px;
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    @include mq-max(sm) {
      font-size: 20px;
      padding: 25px 20px;
    }
    &::after {
      content: none;
    }
    &>span {
      padding-right: 0;
    }

    &--mail {
      &>span {
        &::before {
          @include fa('f0e0');
          font-weight: 100;
          margin-right: 12px;
        }
      }
    }
    &--external {
      &:after {
        content: none !important;
      }
      &>span {
        &::after {
          @include fa('f35d');
          margin-left: 12px;
        }
      }
    }
    &--minHeight {
      min-height: 110px;
      @include mq-max(sm) {
        min-height: auto;
      }
    }
  }
}


.c-cvAreaInfo {
  background-color: $white;
  &__inner {
    padding: 21px 15px;
    color: $keyColor;
    font-weight: 600;
    text-align: center;
  }
  &__head {}
  &__tel {
    display: inline-block;
    font-size: 26px;
    margin-right: 10px;
    @include mq-max(md) {
      font-size: 20px;
    }
  }
  &__number {
    font-size: 37px;
    line-height: 1;
    @include mq-max(md) {
      font-size: 28px;
    }
  }

  &__bottom {
    margin-top: 2px;
  }
  &__term {
    font-size: 16px;
    line-height: 1.5;
    @include mq-max(md) {
      font-size: 15px;
    }
  }
}
