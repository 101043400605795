@use "../global" as *;
//タイムライン

.c-timeline {
  // &__list {}
  &__item {
    position: relative;
    padding-left: 30px;
    padding-bottom: 25px;
    @include mq-max(xs) {
      padding-left: 25px;
    }
    &::before {
      content: '';
      display: inline-block;
      width: 2px;
      height: 100%;
      background-color: $keyColor;
      position: absolute;
      top: 5px;
      left: 7px;
    }
    &::after {
      content: '';
      width: 16px;
      height: 16px;
      display: inline-block;
      background-color: $white;
      border: 2px solid $keyColor;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 0;
    }
    &:last-child {
      padding-bottom: 0;
      &::before {
        content: none; //ここのコメントアウトを取ると、最後の項目の線が消える
        height: calc(100% - 5px);
      }
    }
  }
  &__heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
  }
  // &__title {}
  &__time {
    margin-right: 1em;
    flex-shrink: 0;
    &:empty {
      margin-right: 0;
    }
  }
  &__desc {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.75;
    flex-grow: 1;
  }
}