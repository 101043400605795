@use "../global" as *;

// business下層で使用
.c-businessCard {
  &::after {
    content: none !important;
  }
  &__inner {
    border: 1px solid $keyColor;
    width: 100%;
    display: flex;
    align-items: stretch;
    @include mq-max(xs) {
      flex-direction: column;
    }
  }
  &__body {
    width: 60%;
    padding: 47px 30px 47px 40px;
    overflow: hidden;
    transition: all $transition;
    @include mq-max(xs) {
      width: 100%;
      padding: 30px 15px 26px;
      text-align: center;
    }
  }
  &__txt {
    font-size: 16px;
    font-weight: 600;
    color: $textColor;
    margin-bottom: 20px;;
    letter-spacing: 0;
    @include mq-max(md) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
  &__catchcopy {
    margin-bottom: 0;
    display: block;
    font-size: 27px;
    font-weight: 600;
    color: $keyColor;
    position: relative;
    padding-right: 30px;
    letter-spacing: 0;
    &::after {
      content: '';
      display: block;
      background: url('/inc/image/common/ico/ico_arrow-blue.svg')no-repeat center center / contain;
      position: absolute;
      right: 0;
      bottom: 14px;
      width: 25px;
      height: 22px;
    }
    @include mq-max(iPadPro-v) {
      &::after {
        bottom: 10px;
      }
    }
    @include mq-max(sm) {
      font-size: 20px;
      padding-right: 30px;
    }
    @include mq-max(xs) {
      &::after {
        bottom: 8px;
      }
    }
  }

  &__thumb {
    width: 40%;
    overflow: hidden;
    flex-shrink: 0;
    @include mq-max(xs) {
      width: 100%;
      aspect-ratio: 321 / 211;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;";
    }
  }

  //ホバー
  @media (any-hover: hover) {
    &:hover {
      .c-businessCard {
        &__body {
          background-color: $accentColor-l;
        }
      }
    }
  }
}


// 運営カード
.c-managementCard {
  &__inner {
    display: flex;
    align-items: stretch;
    @include mq-max(sm) {
      justify-content: center;
    }
  }
  &__imgBox {
    max-width: 195px;
    flex-shrink: 0;
    @include mq-max(md) {
      max-width: 160px;
    }
    @include mq-max(sm) {
      max-width: 195px;
    }
    @include mq-max(xs) {
      max-width: 120px;
    }
  }
  &__img {
    height: 100%;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: "object-fit: cover;";
    }
  }
  &__info {
    background-color: $white;
    flex-grow: 1;
    padding: 35px 33px 35px 33px;
    @include mq-max(content) {
      padding: 35px 15px;
    }
  }
  &__name {
    font-family: $yuGothic;
    font-size: 25px;
    margin-bottom: 5px;
    @include mq-max(md) {
      font-size: 22px;
    }
    @include mq-max(iPadPro-v) {
      font-size: 20px;
    }
    @include mq-max(xs) {
      font-size: 18px;
    }
  }
  &__address {
    line-height: 1.4;
    @include mq-max(md) {
      font-size: 15px;
    }
  }
}


//recruit下層用の関連ページ
.c-recruitCard {
  display: block;
  &__thumb {
    margin-bottom: 18px;
  }
  &__body {
    position: relative;
  }
  &__title {
    color: $black-l;
    font-size: 24px;
    margin-bottom: 0;
    padding-bottom: 15px;
    padding-top: 15px;
    padding-right: 30px;
    border-bottom: 1px solid $keyColor;
    @include mq-max(sm) {
      font-size: 22px;
      width: 100%;
    }
    @include mq-max(xs) {
      font-size: 20px;
    }
  }
  &__arrow {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    width: 28px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: all $transition;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 28px;
      height: 24px;
      background: url('/inc/image/common/ico/ico_arrow-blue-bold.svg')no-repeat center center / contain;
    }
    &::before {
      left: 50%;
    }
    &::after {
      left: 0%;
      opacity: 0;
    }
  }

  // hover
  @media (any-hover: hover) {
    &:hover {
      .c-recruitCard {
        &__title {
          color: $keyColor;
        }
        &__arrow {
          &::before {
            animation: arrowBefore .4s ease 0s 1 normal forwards;
          }
          &::after {
            animation: arrowAfter .4s ease 0s 1 normal forwards;
          }
        }
      }
    }
  }
}
// アニメーション
@keyframes arrowBefore {
  0% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
@keyframes arrowAfter {
  0% {
    left: 0%;
    opacity: 0;
  }
  100% {
    left: 50%;
    opacity: 1;
  }
}


// 先輩インタビュー
.c-interviewCard {
  display: inline-block;
  border: 1px solid #707070;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: all $transition;

  .c-ttl-small {
    & span {
      font-size: 25px;
      font-weight: 700;
      font-family: $yuGothic;
      letter-spacing: .04em;
      background-color: $keyColor;
      padding: 6px 10px 4px;
      @include mq-max(iPadPro-v) {
        font-size: 20px;
      }
    }
  }

  &__thumb {
    position: relative;
  }

  &__info {
    position: absolute;
    bottom: -7%;
    left: 20px;
    &-year {
      margin-bottom: 0;
      & > span {
        background-color: $keyColor;
        padding: 6px 10px;
        color: $white;
        font-size: 14px;
        @include mq-max(iPadPro-v) {
          font-size: 12px;
        }
      }
    }
    &-dept {
      margin-bottom: 18px;
      & > span {
        background-color: $keyColor;
        padding: 6px 10px;
        color: $white;
        font-size: 14px;
        @include mq-max(iPadPro-v) {
          font-size: 12px;
        }
      }
    }
  }

  &__body {
    padding: 38px 25px 17px 25px;
    @include mq-max(xs) {
      padding: 27px 13px 16px 13px;
    }
  }
  &__catch {
    line-height: 1.6;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    @include mq-max(iPadPro-v) {
      font-size: 16px;
    }
  }
  &__txt {
    text-align: right;
    & > span {
      font-family: $poppin;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      padding-right: 24px;
      &::after {
        @include fa('f061');
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

  // hover
  @media (any-hover: hover) {
    &:hover {
      .c-interviewCard__body {
        background-color: $accentColor-l;
      }
      .c-interviewCard__txt {
        & > span {
          &::after {
            right: -4px;
          }
        }
      }
    }
  }

  //layout
  // &__wrapper {}
  &__list {
    display: flex;
    justify-content: center;
    margin: 0 -20px -30px;
    @include mq-max(sm) {
      flex-direction: column;
    }
  }
  &__item {
    max-width: 511px;
    padding: 0 20px 30px;
    @include mq-max(sm) {
      margin: 0 auto;
    }
  }
}